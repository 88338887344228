import axios from "axios";
import { useEffect, useState } from "react";

type Product = {
  id: number;
  name: string;
  img: string;
};

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import { Column } from "../components/lib";

const BackendCommunication = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get<Product[]>(process.env.REACT_APP_BACKEND_URL + "/products");
        setProducts(res.data);
      } catch (err) {
        setError(JSON.stringify(err));
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Column sx={{ flexGrow: 1 }}>
      <Typography variant="h3" textAlign="center" my={2}>
        Cakes
      </Typography>

      {loading ? (
        <Column flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Column>
      ) : error ? (
        <Column>
          <Typography color="error">Error while fetching data</Typography>
        </Column>
      ) : (
        <Grid container spacing={4}>
          {products.map((product) => (
            <Grid key={product.id} item xs={6}>
              <Card>
                <CardMedia sx={{ height: 140 }} image={product.img} title={product.name} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {product.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary"></Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Column>
  );
};

export default BackendCommunication;
