import { useEffect, useState } from "react";
import { Column, Row } from "../components/lib";
import Typography from "@mui/material/Typography";
import { Code } from "../components/Code";
import Box from "@mui/material/Box";

const geolocationAvailable = "geolocation" in navigator;

const GeolocationFeature = () => {
  const [position, setPosition] = useState<{
    coords: { latitude: number; longitude: number; accuracy: number };
    timestamp: GeolocationPosition["timestamp"];
  } | null>(null);
  const [error, setError] = useState<{ code: number; message: string } | null>(null);

  const [permission, setPermission] = useState<PermissionState | null>(null);

  useEffect(() => {
    (async () => {
      const result = await navigator.permissions.query({ name: "geolocation" });
      setPermission(result.state);

      const permissionChangeEventHandler = () => {
        setPermission(result.state);
      };

      result.addEventListener("change", permissionChangeEventHandler);

      return () => {
        result.removeEventListener("change", permissionChangeEventHandler);
      };
    })();
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition({
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
          },
          timestamp: position.timestamp,
        });
      },
      (error) => {
        setError({ code: error.code, message: error.message });
      }
    );
  }, []);

  return (
    <Column gap={2}>
      <Typography variant="h3" textAlign="center" my={2}>
        Geolocation Feature
      </Typography>

      <Box>
        <Typography variant="h6">
          Geolocation available:{" "}
          <Typography color={geolocationAvailable ? "success.main" : "error.main"} component="span" fontWeight="bold">
            {geolocationAvailable.toString()}
          </Typography>
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6">
          Geolocation permission: <b>{permission?.toString()}</b>
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6">Geolocation error:{""}</Typography>
        <Code type={error ? "error" : "success"}>{JSON.stringify(error, null, 2)}</Code>
      </Box>

      <Box>
        <Typography variant="h6">Geolocation data: </Typography>
        <Code>{JSON.stringify(position, null, 2)}</Code>
      </Box>

      <Column>
        <Typography>
          <b>Latitude: </b>
          {position?.coords.latitude || "N/A"}
        </Typography>
        <Typography>
          <b>Longitude: </b>
          {position?.coords.longitude || "N/A"}
        </Typography>
      </Column>
    </Column>
  );
};

export default GeolocationFeature;
