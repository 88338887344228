import { useRef } from "react";
import { Column, Row } from "../components/lib";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const FullscreenFeature = () => {
  const fullscreenWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleEnableFullscreen = () => {
    // document.documentElement.requestFullscreen();
    console.log(fullscreenWrapperRef.current ? "element active" : "element inactive");
    fullscreenWrapperRef.current
      ?.requestFullscreen()
      .then(() => {
        console.log("success");
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Fullscreen Feature
      </Typography>

      <Row justifyContent="space-between">
        <Typography>
          Fullscreen enabled:{" "}
          <span style={{ color: window.document.fullscreenEnabled ? "green" : "red" }}>
            {window.document.fullscreenEnabled + ""}
          </span>
        </Typography>
      </Row>

      <div
        ref={fullscreenWrapperRef}
        style={{ height: 600, backgroundColor: "orange", border: "1px dotted black", margin: 12, padding: 12 }}
      >
        <Typography>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe ratione vitae reiciendis omnis veritatis modi
          perferendis culpa voluptatum reprehenderit exercitationem!
        </Typography>
      </div>

      <Box mt={2}>
        <Button variant="contained" fullWidth onClick={handleEnableFullscreen}>
          Enable fullscreen
        </Button>
      </Box>
    </Column>
  );
};

export default FullscreenFeature;
