import { createBrowserRouter } from "react-router-dom";
import {
  AUTH_ROUTE,
  BACKEND_ROUTE,
  FAQ_ROUTE,
  FULL_SCREEN_FEAT_ROUTE,
  LONG_PAGE_ROUTE,
  META_TAGS_ROUTE,
  SDK_ADS_ROUTE,
  SDK_ROUTE,
  SDK_NATIVE_ROUTE,
  VIDEO_PLAYERS_ROUTE,
  SDK_WALLET_ROUTE,
  GEOLOCATION_FEAT_ROUTE,
} from ".";
import Auth from "../features/auth/Auth";
import BackendCommunication from "../pages/BackendCommunication";
import LongPage from "../pages/LongPage";
import SdkMethods from "../pages/SdkMethods";
import Home from "../pages/Home";
import Layout from "../features/ui/Layout";
import Faq from "../pages/Faq";
import FaqQuestion from "../pages/FaqQuestion";
import SdkAds from "../pages/sdk/SdkAds";
import MetaTags from "../pages/MetaTags";
import PageNotFound from "../pages/PageNotFound";
import VideoPlayers from "../pages/VideoPlayers";
import FullscreenFeature from "../pages/FullscreenFeature";
import SdkNative from "../pages/sdk/SdkNative";
import SdkWallet from "../pages/sdk/SdkWallet";
import GeolocationFeature from "../pages/GeolocationFeature";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },

      {
        path: AUTH_ROUTE,
        element: <Auth />,
      },
      {
        path: BACKEND_ROUTE,
        element: <BackendCommunication />,
      },
      {
        path: META_TAGS_ROUTE,
        element: <MetaTags />,
      },
      {
        path: META_TAGS_ROUTE + "/*",
        element: <MetaTags />,
      },
      {
        path: LONG_PAGE_ROUTE,
        element: <LongPage />,
      },
      {
        path: FAQ_ROUTE + "/:id",
        element: <FaqQuestion />,
      },
      {
        path: FAQ_ROUTE,
        element: <Faq />,
      },
      {
        path: SDK_ROUTE + "/" + SDK_ADS_ROUTE,
        element: <SdkAds />,
      },
      {
        path: SDK_ROUTE + "/" + SDK_WALLET_ROUTE,
        element: <SdkWallet />,
      },
      {
        path: SDK_ROUTE + "/" + SDK_NATIVE_ROUTE,
        element: <SdkNative />,
      },
      {
        path: SDK_ROUTE,
        element: <SdkMethods />,
      },
      {
        path: VIDEO_PLAYERS_ROUTE,
        element: <VideoPlayers />,
      },
      {
        path: FULL_SCREEN_FEAT_ROUTE,
        element: <FullscreenFeature />,
      },
      {
        path: GEOLOCATION_FEAT_ROUTE,
        element: <GeolocationFeature />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);
