import { Link } from "react-router-dom";
import {
  AUTH_ROUTE,
  BACKEND_ROUTE,
  FAQ_ROUTE,
  FULL_SCREEN_FEAT_ROUTE,
  GEOLOCATION_FEAT_ROUTE,
  LONG_PAGE_ROUTE,
  META_TAGS_ROUTE,
  SDK_ROUTE,
  VIDEO_PLAYERS_ROUTE,
} from "../routes";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightSharp from "@mui/icons-material/ChevronRightSharp";
import { Column } from "../components/lib";

// prettier-ignore
const listItems = [
  { to: BACKEND_ROUTE, title: "Backend Communication", description: "Fetching data from tiramisu backend" },
  { to: META_TAGS_ROUTE, title: "Metadata", description: "Tiramisu's backend responses for PiNet metadata using backend method" },
  { to: FAQ_ROUTE, title: "FAQ", description: "Nested routes" },
  { to: SDK_ROUTE, title: "SDK", description: "Pi SDK methods" },
  { to: LONG_PAGE_ROUTE, title: "Long page", description: "Test scroll behavior" },
  { to: AUTH_ROUTE, title: "Auth", description: "User authentication. This route behave differently based whether Tiramisu is opened in PiBrowser vs PiNet. In PiBrowser it should automatically authenticate user. In PiNet it should prompt with Sign In form." },
  { to: LONG_PAGE_ROUTE, title: "Long page", description: "Test scroll behavior" },
  { to: VIDEO_PLAYERS_ROUTE, title: "Video players", description: "Test video players" },
  {to: FULL_SCREEN_FEAT_ROUTE, title: "Fullscreen feature", description: "Test fullscreen feature using Fullscreen API"},
  {to: GEOLOCATION_FEAT_ROUTE, title: "Geolocation feature", description: "Test geolocation using Geolocation API"}
] satisfies Array<{ to: string; title: string; description: string }>

const Home = () => {
  return (
    <Column>
      <Typography variant="h3" textAlign="center" mt={2}>
        Home
      </Typography>

      <List>
        {listItems.map((item, index) => (
          <Link key={index} to={item.to}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={item.title} secondary={item.description} />
                <ListItemIcon>
                  <ChevronRightSharp />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>

      <Typography variant="h6">Test iFrame</Typography>

      <List>
        <a href="https://google.com">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Different domain in iframe" />
              <ListItemIcon>
                <ChevronRightSharp />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </a>
        <a href="https://google.com" target="_blank" rel="noreferrer">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Different domain in target _blank" />
              <ListItemIcon>
                <ChevronRightSharp />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </a>
      </List>
    </Column>
  );
};

export default Home;
