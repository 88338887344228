import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ChevronRightSharp from "@mui/icons-material/ChevronRightSharp";
import { Code } from "../components/Code";
import { META_TAGS_ROUTE } from "../routes";
import { Column } from "../components/lib";
import { PiNetMetadataDTO } from "../types/PiNetMetadata";

const routes = ["1", "2", "app", "player-only", "player-with-image", "article"];

const MetaTags = () => {
  const [meta, setMeta] = useState<PiNetMetadataDTO | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        setMeta(null);
        setLoading(true);
        setError(null);
        // pinet will pass pathname only
        const res = await axios.get<PiNetMetadataDTO>(
          process.env.REACT_APP_BACKEND_URL + "/pinet/meta?pathname=" + window.location.pathname
        );
        setMeta(res.data);
      } catch (err) {
        setError(JSON.stringify(err));
      } finally {
        setLoading(false);
      }
    })();
  }, [location.pathname]);

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Meta Tags
      </Typography>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to={"/" + META_TAGS_ROUTE}>
          Meta Tags
        </Link>
        {location.pathname.split("/").length > 2 && (
          <Typography display="inline">{location.pathname.split("/")[2]}</Typography>
        )}
      </Breadcrumbs>

      <List>
        {routes.map((route, index) => (
          <Link key={index} to={route}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={`/meta-tags/${route}`} />
                <ListItemIcon>
                  <ChevronRightSharp />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>

      {loading ? (
        <Box p={10}>
          <CircularProgress />
        </Box>
      ) : error || !meta ? (
        <Box>
          <Typography gutterBottom color="error">
            Error while fetching data
          </Typography>
          {error && <Code>{error}</Code>}
        </Box>
      ) : (
        <DisplayTags dto={meta} />
      )}
    </Column>
  );
};

export default MetaTags;

const DisplayTags = ({ dto }: { dto: PiNetMetadataDTO }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        image={
          typeof dto.openGraph?.images === "string"
            ? dto.openGraph.images
            : Array.isArray(dto.openGraph?.images) && dto.openGraph?.images[0] === "string"
            ? dto.openGraph.images[0]
            : Array.isArray(dto.openGraph?.images) && dto.openGraph?.images
            ? // @ts-ignore
              dto.openGraph?.images[0]?.url
            : // @ts-ignore
              dto.openGraph?.images?.url
        }
        alt="og image"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {dto.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {dto.description}
        </Typography>

        <Box mt={6}>
          <Typography variant="h6">Open graph:</Typography>
          <Typography gutterBottom component="div">
            {dto.openGraph?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {dto.openGraph?.description}
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="h6">Raw response:</Typography>
          <Code>{JSON.stringify(dto, null, 2)}</Code>
        </Box>
      </CardContent>
    </Card>
  );
};
