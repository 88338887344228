import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { authenticateUser } from "./authSlice";

const Auth = () => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [piSdkError, setPiSdkError] = useState(null);

  useEffect(() => {
    Pi.init({ version: "2.0" })
      .then(() => dispatch(authenticateUser()))
      .catch((err) => setPiSdkError(err));
  }, [dispatch]);

  if (piSdkError)
    return (
      <div>
        <h2 style={{ color: "red" }}>An error occurred while connecting to Pi SDK.</h2>
      </div>
    );

  if (auth.status === "error")
    return (
      <div>
        <h2 style={{ color: "red" }}>An error occurred while authenticating with Pi.</h2>
        <p>
          <b>Error:</b> {JSON.stringify(auth.error)}
        </p>
        <button onClick={() => dispatch(authenticateUser())}>Retry</button>
      </div>
    );

  if (auth.status === "loading")
    return (
      <div>
        <h2>Authenticating with Pi Network...</h2>
      </div>
    );

  if (auth.status === "success") {
    return (
      <div>
        <h2>Welcome @{auth.user.username}</h2>
      </div>
    );
  }
};

export default Auth;
