import { useLocation } from "react-router-dom";
import { Column } from "../components/lib";
import Typography from "@mui/material/Typography";

const PageNotFound = () => {
  const location = useLocation();

  const combinedLocation = location.pathname + location.search + location.hash;

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Page Not Found
      </Typography>
      <Typography>
        There is not page at:{" "}
        <Typography component="span" fontWeight={600}>
          {combinedLocation}
        </Typography>
      </Typography>
    </Column>
  );
};

export default PageNotFound;
