import { useState } from "react";
import { assertPiSdkErrorShape } from "../utils";
import { SDK_ADS_ROUTE, SDK_NATIVE_ROUTE, SDK_WALLET_ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";
import { Code } from "../components/Code";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Column } from "../components/lib";

const SdkMethods = () => {
  const navigate = useNavigate();

  const [authenticateRes, setAuthenticateRes] = useState<Pi.SdkResponse | null>(null);
  const [createPaymentRes, setCreatePaymentRes] = useState<{
    createPayment: Pi.SdkResponse | null;
    onReadyForServerApproval: Pi.SdkResponse | null;
    onCancel: Pi.SdkResponse | null;
    onError: Pi.SdkResponse | null;
  }>({ createPayment: null, onReadyForServerApproval: null, onCancel: null, onError: null });
  const [openConversationRes, setOpenConversationRes] = useState<Pi.SdkResponse | null>(null);
  const [scanQrCodeRes, setScanQrCodeRes] = useState<Pi.SdkResponse | null>(null);
  const [qrScannerFacingMode, setQrScannerFacingMode] = useState<"environment" | "user">("environment");
  const [getPiHostAppNameRes, setGetPiHostAppNameRes] = useState<Pi.SdkResponse | null>(null);
  const [getPiHostAppInfoRes, setGetPiHostAppInfoRes] = useState<Pi.SdkResponse | null>(null);
  const [showDownloadPiBrowserModalRes, setShowDownloadPiBrowserModalRes] = useState<Pi.SdkResponse | null>(null);
  const [getSafeAreaInsetsRes, setGetSafeAreaInsetsRes] = useState<Pi.SdkResponse | null>(null);

  const authenticate = async () => {
    try {
      const res = await Pi.authenticate(["username", "payments"]);
      setAuthenticateRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setAuthenticateRes({ type: "error", error: err.message });
    }
  };

  const createPayment = () => {
    const paymentData = { amount: 1, memo: "test memo", metadata: { some_property: 1 } };
    const paymentCallbacks = {
      onReadyForServerApproval: (res: any) => {
        if (res instanceof Error) {
          setCreatePaymentRes((prev) => ({ ...prev, onReadyForServerApproval: { type: "error", error: res.message } }));
        } else {
          setCreatePaymentRes((prev) => ({
            ...prev,
            onReadyForServerApproval: { type: "error", error: JSON.stringify(res) },
          }));
        }
      },
      onReadyForServerCompletion: (res: any) => {
        if (res instanceof Error) {
          setCreatePaymentRes((prev) => ({ ...prev, onReadyForServerApproval: { type: "error", error: res.message } }));
        } else {
          setCreatePaymentRes((prev) => ({
            ...prev,
            onReadyForServerApproval: { type: "error", error: JSON.stringify(res) },
          }));
        }
      },
      onCancel: (res: any) => {
        if (res instanceof Error) {
          setCreatePaymentRes((prev) => ({ ...prev, onCancel: { type: "error", error: res.message } }));
        } else {
          setCreatePaymentRes((prev) => ({ ...prev, onCancel: { type: "error", error: JSON.stringify(res) } }));
        }
      },
      onError: (res: any) => {
        if (res instanceof Error) {
          setCreatePaymentRes((prev) => ({ ...prev, onError: { type: "error", error: res.message } }));
        } else {
          setCreatePaymentRes((prev) => ({ ...prev, onError: { type: "error", error: JSON.stringify(res) } }));
        }
      },
    };

    try {
      const res = Pi.createPayment(paymentData, paymentCallbacks);
      setCreatePaymentRes((prev) => ({
        ...prev,
        createPayment: { type: "success", value: JSON.stringify(res, null, 2) },
      }));
    } catch (err) {
      /** @FIXME no idea why TS has issue with below: */
      // assertPiSdkErrorShape(err) &&
      // setCreatePaymentRes((prev) => ({ ...prev, createPayment: { type: "error", error: err.message } }));

      const message = (err as Error)?.message || "N/A";
      setCreatePaymentRes((prev) => ({ ...prev, createPayment: { type: "error", error: message } }));
    }
  };

  const openConversation = async () => {
    try {
      const res = await Pi.openConversation(1);
      setOpenConversationRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setOpenConversationRes({ type: "error", error: err.message });
    }
  };

  const scanQrCode = async () => {
    try {
      const res = await Pi.scanQrCode({ facingMode: qrScannerFacingMode });
      setScanQrCodeRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setScanQrCodeRes({ type: "error", error: err.message });
    }
  };

  const getPiHostAppName = async () => {
    try {
      const res = await Pi.getPiHostAppName();
      setGetPiHostAppNameRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setGetPiHostAppNameRes({ type: "error", error: err.message });
    }
  };

  const getPiHostAppInfo = async () => {
    try {
      const res = await Pi.getPiHostAppInfo();
      setGetPiHostAppInfoRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setGetPiHostAppInfoRes({ type: "error", error: err.message });
    }
  };

  const showDownloadPiBrowserModal = async () => {
    try {
      const res = await Pi.showDownloadPiBrowserModal();
      setShowDownloadPiBrowserModalRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setShowDownloadPiBrowserModalRes({ type: "error", error: err.message });
    }
  };

  const getSafeAreaInsets = async () => {
    try {
      const res = await Pi.getSafeAreaInsets();
      setGetSafeAreaInsetsRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setGetSafeAreaInsetsRes({ type: "error", error: err.message });
    }
  };

  return (
    <Column>
      <Typography variant="h3" my={2} textAlign="center">
        Test PI SDK Methods
      </Typography>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Ads
        </Typography>
        <Button variant="contained" size="small" onClick={() => navigate(SDK_ADS_ROUTE)}>
          Navigate to Ads Tests
        </Button>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Wallet
        </Typography>
        <Button variant="contained" size="small" onClick={() => navigate(SDK_WALLET_ROUTE)}>
          Navigate to Wallet Tests
        </Button>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Native
        </Typography>
        <Button variant="contained" size="small" onClick={() => navigate(SDK_NATIVE_ROUTE)}>
          Navigate to Native Tests
        </Button>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.authenticate
        </Typography>
        <Button variant="contained" size="small" onClick={authenticate}>
          Pi.authenticate
        </Button>
        {authenticateRes?.type === "error" ? (
          <Code mt={1} type="error">
            {authenticateRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {authenticateRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.createPayment
        </Typography>
        <Button variant="contained" size="small" onClick={createPayment}>
          Pi.createPayment
        </Button>
        {Object.entries(createPaymentRes).map(([key, res]) => (
          <Box>
            <Typography variant="h6">{key}</Typography>
            {res?.type === "error" ? (
              <Code mt={1} type="error">
                {res.error}
              </Code>
            ) : (
              <Code mt={1} type="success">
                {res?.value}
              </Code>
            )}
          </Box>
        ))}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.openConversation
        </Typography>
        <Button variant="contained" size="small" onClick={openConversation}>
          Open
        </Button>
        {openConversationRes?.type === "error" ? (
          <Code mt={1} type="error">
            {openConversationRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {openConversationRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.scanQrCode
        </Typography>
        <FormControl fullWidth sx={{ mb: 1 }}>
          <InputLabel id="qr-scanner-facing-mode">Age</InputLabel>
          <Select
            labelId="qr-scanner-facing-mode"
            value={qrScannerFacingMode}
            label="QR Scanner Facing Mode"
            size="small"
            onChange={(e) => setQrScannerFacingMode(e.target.value as "environment" | "user")}
          >
            <MenuItem value="environment">Environment</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" size="small" onClick={scanQrCode}>
          Pi.scanQrCode
        </Button>
        {scanQrCodeRes?.type === "error" ? (
          <Code mt={1} type="error">
            {scanQrCodeRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {scanQrCodeRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.getPiHostAppName
        </Typography>
        <Button variant="contained" size="small" onClick={getPiHostAppName}>
          Pi.getPiHostAppName
        </Button>
        {getPiHostAppNameRes?.type === "error" ? (
          <Code mt={1} type="error">
            {getPiHostAppNameRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {getPiHostAppNameRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.getPiHostAppInfo
        </Typography>
        <Button variant="contained" size="small" onClick={getPiHostAppInfo}>
          Pi.getPiHostAppInfo
        </Button>
        {getPiHostAppInfoRes?.type === "error" ? (
          <Code mt={1} type="error">
            {getPiHostAppInfoRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {getPiHostAppInfoRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.showDownloadPiBrowserModal
        </Typography>
        <Button variant="contained" size="small" onClick={showDownloadPiBrowserModal}>
          Pi.showDownloadPiBrowserModal
        </Button>
        {showDownloadPiBrowserModalRes?.type === "error" ? (
          <Code mt={1} type="error">
            {showDownloadPiBrowserModalRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {showDownloadPiBrowserModalRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.getSafeAreaInsets
        </Typography>
        <Button variant="contained" size="small" onClick={getSafeAreaInsets}>
          Pi.getSafeAreaInsets
        </Button>
        {getSafeAreaInsetsRes?.type === "error" ? (
          <Code mt={1} type="error">
            {getSafeAreaInsetsRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {getSafeAreaInsetsRes?.value}
          </Code>
        )}
      </Box>
    </Column>
  );
};

export default SdkMethods;
