import { useEffect, useState } from "react";
import { assertPiSdkErrorShape } from "../../utils";
import { Code } from "../../components/Code";
import axios from "axios";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Column } from "../../components/lib";

const SdkAds = () => {
  const [adType, setAdType] = useState<Pi.AdType>("interstitial");

  useEffect(() => {
    setIsAdReadyRes(null);
    setRequestAdRes(null);
    setShowAdRes(null);
  }, [adType]);

  const [isAdReadyRes, setIsAdReadyRes] = useState<Pi.SdkResponse | null>(null);
  const [requestAdRes, setRequestAdRes] = useState<Pi.SdkResponse | null>(null);
  const [showAdRes, setShowAdRes] = useState<Pi.SdkResponse | null>(null);

  const [rewardedAdData, setRewardedAdData] = useState<{
    adId: string;
    status: "N/A" | "error" | "success";
    data?: any;
  }>({
    adId: "",
    status: "N/A",
    data: null,
  });

  const isAdReady = async () => {
    try {
      const res = await Pi.Ads.isAdReady(adType);
      setIsAdReadyRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setIsAdReadyRes({ type: "error", error: err.message });
    }
  };

  const requestAd = async () => {
    try {
      const res = await Pi.Ads.requestAd(adType);
      setRequestAdRes({ type: "success", value: JSON.stringify(res, null, 2) });
    } catch (err) {
      assertPiSdkErrorShape(err) && setRequestAdRes({ type: "error", error: err.message });
    }
  };

  const showAd = async () => {
    try {
      const res = await Pi.Ads.showAd(adType);
      setShowAdRes({ type: "success", value: JSON.stringify(res, null, 2) });
      if (res.type === "rewarded") {
        // @ts-ignore
        setRewardedAdData(res);
      }
    } catch (err) {
      assertPiSdkErrorShape(err) && setShowAdRes({ type: "error", error: err.message });
    }
  };

  const handleCheckRewardedAdStatus = async (adId: string) => {
    try {
      const res = await axios.get(process.env.REACT_APP_PLATFORM_API_URL + "/ads_network/status/" + adId, {
        headers: {
          Authorization: `Key ${process.env.REACT_APP_PI_API_KEY}`,
        },
      });
      setRewardedAdData((prev) => ({ ...prev, status: "success", data: JSON.stringify(res.data, null, 2) }));
    } catch (err) {
      alert(JSON.stringify(err, null, 2));
      setRewardedAdData((prev) => ({ ...prev, status: "error" }));
    }
  };

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Test Pi SDK Ads
      </Typography>

      <Box mb={2}>
        <FormControl fullWidth>
          <InputLabel id="ad-type-select-label">Add Type</InputLabel>
          <Select
            labelId="ad-type-select-label"
            id="ad-type-select"
            value={adType}
            label="Ad Type"
            onChange={(e) => setAdType(e.target.value as Pi.AdType)}
          >
            <MenuItem value="interstitial">Interstitial</MenuItem>
            <MenuItem value="rewarded">Rewarded</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.Ads.isAdReady
        </Typography>
        <Button variant="contained" size="small" onClick={isAdReady}>
          Pi.Ads.isAdReady
        </Button>
        {isAdReadyRes?.type === "error" ? (
          <Code mt={1} type="error">
            {isAdReadyRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {isAdReadyRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.Ads.requestAd
        </Typography>
        <Button variant="contained" size="small" onClick={requestAd}>
          Pi.Ads.requestAd
        </Button>
        {requestAdRes?.type === "error" ? (
          <Code mt={1} type="error">
            {requestAdRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {requestAdRes?.value}
          </Code>
        )}
      </Box>

      <Box mb={2}>
        <Typography variant="h6" mb={1}>
          Pi.Ads.showAd
        </Typography>
        <Button variant="contained" size="small" onClick={showAd}>
          Pi.Ads.showAd
        </Button>
        {showAdRes?.type === "error" ? (
          <Code mt={1} type="success">
            {showAdRes.error}
          </Code>
        ) : (
          <Code mt={1} type="success">
            {showAdRes?.value}
          </Code>
        )}

        {rewardedAdData.adId && (
          <Box mt={1}>
            <Typography variant="h6">
              Check rewarded ad status for <pre style={{ display: "inline" }}>adId:</pre>
            </Typography>
            <Code my={1}>{rewardedAdData.adId}</Code>
            <Button variant="contained" onClick={() => handleCheckRewardedAdStatus(rewardedAdData.adId)}>
              Check
            </Button>
            <Code mt={1} type={rewardedAdData.status === "N/A" ? undefined : rewardedAdData.status}>
              {rewardedAdData.data}
            </Code>
          </Box>
        )}
      </Box>
    </Column>
  );
};

export default SdkAds;
