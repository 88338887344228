import React from "react";
import { Column } from "../components/lib";
import { Typography } from "@mui/material";

const VideoPlayers = () => {
  return (
    <Column gap={1} pb={10}>
      <Typography variant="h3" textAlign="center" my={2}>
        Test Video Players
      </Typography>

      <Typography variant="h5">YouTube</Typography>

      <Typography variant="h6">allowFullScreen = true</Typography>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=Wh7I0or7NrH60Uzk&amp;start=44"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        allowFullScreen={true}
      ></iframe>

      <Typography variant="h5">allowFullScreen = false</Typography>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=Wh7I0or7NrH60Uzk&amp;start=44"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen={false}
      ></iframe>

      <Typography variant="h5">Vimeo</Typography>
      <iframe
        src="https://player.vimeo.com/video/717817467?h=2e1e0f44c0"
        width="100%"
        height="230"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Column>
  );
};

export default VideoPlayers;
