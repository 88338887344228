import { Outlet, useNavigate } from "react-router-dom";
import AppBar from "../../components/AppBar";
import { Column } from "../../components/lib";
import Container from "@mui/material/Container";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useEffect, useLayoutEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Construction, Home, Quiz } from "@mui/icons-material";
import { FAQ_ROUTE, SDK_ROUTE } from "../../routes";
import { getHostAppName, getSafeAreaInsets } from "./uiSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const Layout = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const ui = useAppSelector((s) => s.ui);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(getHostAppName());
    dispatch(getSafeAreaInsets());
  }, [dispatch]);

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        return navigate("/");
      case 1:
        return navigate(SDK_ROUTE);
      case 2:
        return navigate(FAQ_ROUTE);
    }
  }, [tabIndex]);

  return (
    <Column height="100vh">
      <AppBar />

      <Column
        flex={1}
        sx={(theme) => ({
          overflowY: "auto",
          // app bar height:
          mt: `calc(${theme.spacing(5)} + ${ui.safeAreaInsets.top}px)`,
          // bottom nav height:
          mb: `calc(${theme.spacing(7)} + ${ui.safeAreaInsets.bottom}px)`,
          pt: 2,
        })}
      >
        <Container>
          <Outlet />
        </Container>
      </Column>

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, pb: ui.safeAreaInsets.bottom + "px" }}
        elevation={3}
      >
        <BottomNavigation showLabels value={tabIndex} onChange={(_event, newValue) => setTabIndex(newValue)}>
          <BottomNavigationAction label="Home" icon={<Home />} />
          <BottomNavigationAction label="SDK" icon={<Construction />} />
          <BottomNavigationAction label="FAQ" icon={<Quiz />} />
        </BottomNavigation>
      </Paper>
    </Column>
  );
};

export default Layout;
