import { useParams } from "react-router-dom";
import { faqs } from "./Faq";
import Typography from "@mui/material/Typography";
import { Column } from "../components/lib";

const FaqQuestion = () => {
  const { id } = useParams<{ id: string }>();
  const faq = faqs.find((faq) => faq.id === id);

  if (!faq) {
    return (
      <Column>
        <Typography variant="h3" my={2}>
          Question not found
        </Typography>
      </Column>
    );
  }

  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        {faq.question}
      </Typography>
      <Typography>{faq.answer}</Typography>
    </Column>
  );
};

export default FaqQuestion;
