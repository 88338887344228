import { ChevronRightSharp } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Column } from "../components/lib";

export const faqs = [
  {
    id: "1",
    question: "What is Tiramisu",
    answer:
      'Tiramisu (Italian: tiramisù, from tirami su, "pick me up" or "cheer me up") is a coffee-flavoured Italian dessert. It is made of ladyfingers (savoiardi) dipped in coffee, layered with a whipped mixture of eggs, sugar, and mascarpone cheese, flavoured with cocoa. The recipe has been adapted into many varieties of cakes and other desserts. Its origin is disputed between the Italian regions of Veneto and Friuli Venezia Giulia.',
  },
  {
    id: "2",
    question: "What is Semifreddo?",
    answer: `Semifreddo (Italian for "half-cold" is a class of frozen desserts similar to ice cream. The main ingredients are egg yolks, sugar, and cream. It has the texture of frozen mousse or cake. The dessert's Spanish counterpart is called semifrío. It was created during the 19th century, but did not gain popularity until the early 20th century.`,
  },
];

const Faq = () => {
  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Faq
      </Typography>

      <List>
        {faqs.map((faq, index) => (
          <Link key={index} to={faq.id}>
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <ListItemText primary={faq.question} />
                <ListItemIcon>
                  <ChevronRightSharp />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Column>
  );
};

export default Faq;
