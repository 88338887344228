import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { Column } from "./components/lib";
import Typography from "@mui/material/Typography";

const App = () => {
  const [piSdkLoading, setPiSdkLoading] = useState(true);
  const [piSdkError, setPiSdkError] = useState(false);

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        await Pi.init({ version: "2.0" });
      } catch (err) {
        setPiSdkError(true);
      } finally {
        setPiSdkLoading(false);
      }
    };
    initializeSDK();
  }, []);

  if (piSdkError)
    return (
      <Column height="100vh" justifyContent="center" alignItems="center">
        <Typography textAlign="center">Failed to initialize Pi SDK. Please try again later...</Typography>
      </Column>
    );

  if (piSdkLoading)
    return (
      <Column height="100vh" justifyContent="center" alignItems="center">
        <Typography textAlign="center">Loading...</Typography>
      </Column>
    );

  return <RouterProvider router={router} />;
};

export default App;
