import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";

import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Row } from "./lib";
import { authenticateUser } from "../features/auth/authSlice";
import { CircularProgress } from "@mui/material";

const AppBar = () => {
  const navigate = useNavigate();
  const auth = useAppSelector((s) => s.auth);
  const ui = useAppSelector((s) => s.ui);
  const dispatch = useAppDispatch();

  return (
    <MuiAppBar position="fixed">
      <Toolbar>
        <Row flex={1} justifyContent="space-between" alignItems="center" pt={ui.safeAreaInsets.top + "px"}>
          {/* left */}
          <Box>
            {ui.hostApp !== "pi-browser" && (
              <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
          </Box>

          {/* right */}
          <Box>
            {auth.status === "success" ? (
              <Typography>@{auth.user.username}</Typography>
            ) : auth.status === "loading" ? (
              <CircularProgress color="inherit" />
            ) : (
              <Button color="inherit" onClick={() => dispatch(authenticateUser())}>
                Login
              </Button>
            )}
          </Box>
        </Row>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
