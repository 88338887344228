import { Typography } from "@mui/material";
import { Column } from "../components/lib";

const LongPage = () => {
  return (
    <Column>
      <Typography variant="h3" textAlign="center" my={2}>
        Long Page
      </Typography>

      <Column gap={2}>
        <Typography variant="h5">Lorem ipsum 1</Typography>
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet quia a consequuntur laborum nobis. Sunt maxime
          non, quod neque repellat perferendis autem cum iusto doloribus accusantium sit labore a eos dolores aliquid
          ipsa officiis tempora nihil animi? Error, quaerat est.
        </Typography>
        <Typography variant="h5">Lorem ipsum 2</Typography>
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, ullam a unde doloremque consequuntur illo rerum
          repellendus, impedit consequatur, facilis pariatur repudiandae? Harum quisquam perferendis dolores,
          consequuntur soluta necessitatibus facere!
        </Typography>
        <Typography variant="h5">Lorem ipsum 3</Typography>
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eveniet quasi maiores saepe autem et pariatur
          rerum, dicta ipsum quae perferendis tempore labore? Accusamus unde repudiandae possimus doloremque modi
          consequatur mollitia architecto laborum obcaecati? Odio sunt delectus voluptatibus aspernatur fugiat sed
          debitis dicta quos vitae deleniti qui veritatis alias ea nulla modi aliquam at, odit perspiciatis ducimus
          consequuntur quibusdam labore? Assumenda, nemo eos a debitis ipsam voluptatum aut, distinctio, quibusdam
          possimus sed commodi molestias error explicabo repellat eum. Autem possimus, rem aspernatur, itaque ipsum vel,
          culpa inventore enim aperiam temporibus repellat eligendi odit distinctio laudantium obcaecati quo unde
          delectus deleniti assumenda perferendis? Obcaecati, omnis quod iure mollitia dolorum quos consequatur optio
          impedit. Eum perferendis quisquam dolorem repudiandae at, commodi voluptates!
        </Typography>
        <Typography>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus eveniet nesciunt incidunt, aperiam earum
          corporis, rerum deleniti cupiditate eos, repellat eius in similique illum optio atque ab aliquid eaque
          mollitia.
        </Typography>{" "}
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eveniet quasi maiores saepe autem et pariatur
          rerum, dicta ipsum quae perferendis tempore labore? Accusamus unde repudiandae possimus doloremque modi
          consequatur mollitia architecto laborum obcaecati? Odio sunt delectus voluptatibus aspernatur fugiat sed
          debitis dicta quos vitae deleniti qui veritatis alias ea nulla modi aliquam at, odit perspiciatis ducimus
          consequuntur quibusdam labore? Assumenda, nemo eos a debitis ipsam voluptatum aut, distinctio, quibusdam
          possimus sed commodi molestias error explicabo repellat eum. Autem possimus, rem aspernatur, itaque ipsum vel,
          culpa inventore enim aperiam temporibus repellat eligendi odit distinctio laudantium obcaecati quo unde
          delectus deleniti assumenda perferendis? Obcaecati, omnis quod iure mollitia dolorum quos consequatur optio
          impedit. Eum perferendis quisquam dolorem repudiandae at, commodi voluptates!
        </Typography>
        <Typography>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus eveniet nesciunt incidunt, aperiam earum
          corporis, rerum deleniti cupiditate eos, repellat eius in similique illum optio atque ab aliquid eaque
          mollitia.
        </Typography>{" "}
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eveniet quasi maiores saepe autem et pariatur
          rerum, dicta ipsum quae perferendis tempore labore? Accusamus unde repudiandae possimus doloremque modi
          consequatur mollitia architecto laborum obcaecati? Odio sunt delectus voluptatibus aspernatur fugiat sed
          debitis dicta quos vitae deleniti qui veritatis alias ea nulla modi aliquam at, odit perspiciatis ducimus
          consequuntur quibusdam labore? Assumenda, nemo eos a debitis ipsam voluptatum aut, distinctio, quibusdam
          possimus sed commodi molestias error explicabo repellat eum. Autem possimus, rem aspernatur, itaque ipsum vel,
          culpa inventore enim aperiam temporibus repellat eligendi odit distinctio laudantium obcaecati quo unde
          delectus deleniti assumenda perferendis? Obcaecati, omnis quod iure mollitia dolorum quos consequatur optio
          impedit. Eum perferendis quisquam dolorem repudiandae at, commodi voluptates!
        </Typography>
        <Typography>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus eveniet nesciunt incidunt, aperiam earum
          corporis, rerum deleniti cupiditate eos, repellat eius in similique illum optio atque ab aliquid eaque
          mollitia.
        </Typography>{" "}
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eveniet quasi maiores saepe autem et pariatur
          rerum, dicta ipsum quae perferendis tempore labore? Accusamus unde repudiandae possimus doloremque modi
          consequatur mollitia architecto laborum obcaecati? Odio sunt delectus voluptatibus aspernatur fugiat sed
          debitis dicta quos vitae deleniti qui veritatis alias ea nulla modi aliquam at, odit perspiciatis ducimus
          consequuntur quibusdam labore? Assumenda, nemo eos a debitis ipsam voluptatum aut, distinctio, quibusdam
          possimus sed commodi molestias error explicabo repellat eum. Autem possimus, rem aspernatur, itaque ipsum vel,
          culpa inventore enim aperiam temporibus repellat eligendi odit distinctio laudantium obcaecati quo unde
          delectus deleniti assumenda perferendis? Obcaecati, omnis quod iure mollitia dolorum quos consequatur optio
          impedit. Eum perferendis quisquam dolorem repudiandae at, commodi voluptates!
        </Typography>
        <Typography>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus eveniet nesciunt incidunt, aperiam earum
          corporis, rerum deleniti cupiditate eos, repellat eius in similique illum optio atque ab aliquid eaque
          mollitia.
        </Typography>
      </Column>
    </Column>
  );
};

export default LongPage;
