import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const SCOPES = ["username", "payments"] satisfies Pi.Scope[];

export type AuthState =
  | {
      status: "success";
      user: Pi.User<"username">;
      accessToken: string;
      error: null;
    }
  | {
      status: "uninitialized" | "loading";
      user: null;
      accessToken: null;
      error: null;
    }
  | {
      status: "error";
      user: null;
      accessToken: null;
      error: any;
    };

const initialState: AuthState = {
  status: "uninitialized",
  user: null,
  accessToken: null,
  error: null,
};

export const authenticateUser = createAsyncThunk("auth/authenticateUser", async (_, { rejectWithValue }) => {
  try {
    const authResponse = await Pi.authenticate(SCOPES, () => {});
    return { user: authResponse.user, accessToken: authResponse.accessToken };
  } catch (err) {
    // @ts-ignore
    return rejectWithValue(err?.message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState as AuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.status = "loading";
        state.user = null;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
        state.user = null;
      });
  },
});

export default authSlice.reducer;
