import { Box, BoxProps } from "@mui/material";

type CodeProps = {
  children: any;
  type?: "error" | "success";
} & Exclude<BoxProps, "children">;

export const Code = ({ children, type, ...boxProps }: CodeProps) => {
  return (
    <Box
      component="pre"
      {...boxProps}
      style={{
        display: "block",
        width: "100%",
        padding: children ? 10 : 0,
        fontSize: 14,
        backgroundColor: "#222436",
        color: type === "success" ? "#4FD7BE" : type === "error" ? "#F4717B" : "#C5D1F2",
        fontFamily: "monospace",
        wordBreak: "break-word",
        overflowX: "scroll",
        ...boxProps.style,
      }}
    >
      {children}
    </Box>
  );
};
