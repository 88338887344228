export const INDEX_ROUTE = "/";
export const AUTH_ROUTE = "auth";

export const SDK_ROUTE = "sdk";
export const SDK_ADS_ROUTE = "ads";
export const SDK_WALLET_ROUTE = "wallet";
export const SDK_NATIVE_ROUTE = "native";

export const FAQ_ROUTE = "faq";
export const ABOUT_US_ROUTE = "about-us";

export const LONG_PAGE_ROUTE = "long-page";
export const BACKEND_ROUTE = "backend";
export const META_TAGS_ROUTE = "meta-tags";
export const VIDEO_PLAYERS_ROUTE = "video-players";
export const FULL_SCREEN_FEAT_ROUTE = "fullscreen";
export const GEOLOCATION_FEAT_ROUTE = "geolocation";
