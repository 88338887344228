import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export type UiState = {
  hostApp: Pi.HostAppName | undefined;
  safeAreaInsets: Pi.SafeAreaInsets;
};

const initialState: UiState = {
  hostApp: undefined,
  safeAreaInsets: { top: 0, bottom: 0 },
};

export const getHostAppName = createAsyncThunk<Pi.HostAppName>("ui/getHostAppName", async (_, { rejectWithValue }) => {
  try {
    const response = await Pi.getPiHostAppName();
    return response;
  } catch (err) {
    // @ts-ignore
    return rejectWithValue(err?.message);
  }
});

export const getSafeAreaInsets = createAsyncThunk<Pi.SafeAreaInsets>(
  "ui/getSafeAreaInsets",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Pi.getSafeAreaInsets();
      return response.safeAreaInsets;
    } catch (err) {
      // @ts-ignore
      return rejectWithValue(err?.message);
    }
  }
);

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState as UiState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHostAppName.fulfilled, (state, action) => {
        state.hostApp = action.payload;
      })
      .addCase(getSafeAreaInsets.fulfilled, (state, action) => {
        state.safeAreaInsets = action.payload;
      });
  },
});

export default uiSlice.reducer;
